exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-asterisk-operation-js": () => import("./../../../src/pages/asterisk_operation.js" /* webpackChunkName: "component---src-pages-asterisk-operation-js" */),
  "component---src-pages-callintelligence-js": () => import("./../../../src/pages/callintelligence.js" /* webpackChunkName: "component---src-pages-callintelligence-js" */),
  "component---src-pages-cloudpbx-js": () => import("./../../../src/pages/cloudpbx.js" /* webpackChunkName: "component---src-pages-cloudpbx-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-freevoice-js": () => import("./../../../src/pages/freevoice.js" /* webpackChunkName: "component---src-pages-freevoice-js" */),
  "component---src-pages-hamaphone-js": () => import("./../../../src/pages/hamaphone.js" /* webpackChunkName: "component---src-pages-hamaphone-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-mrfax-js": () => import("./../../../src/pages/mrfax.js" /* webpackChunkName: "component---src-pages-mrfax-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-recruit-js": () => import("./../../../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */),
  "component---src-pages-siptrunk-gateway-js": () => import("./../../../src/pages/siptrunk_gateway.js" /* webpackChunkName: "component---src-pages-siptrunk-gateway-js" */),
  "component---src-pages-siptrunk-js": () => import("./../../../src/pages/siptrunk.js" /* webpackChunkName: "component---src-pages-siptrunk-js" */),
  "component---src-pages-tech-blog-js": () => import("./../../../src/pages/tech_blog.js" /* webpackChunkName: "component---src-pages-tech-blog-js" */),
  "component---src-pages-telecom-js": () => import("./../../../src/pages/telecom.js" /* webpackChunkName: "component---src-pages-telecom-js" */),
  "component---src-pages-termofuse-js": () => import("./../../../src/pages/termofuse.js" /* webpackChunkName: "component---src-pages-termofuse-js" */),
  "component---src-pages-topics-1-js": () => import("./../../../src/pages/topics-1.js" /* webpackChunkName: "component---src-pages-topics-1-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wigeta-pbx-js": () => import("./../../../src/pages/wigeta_pbx.js" /* webpackChunkName: "component---src-pages-wigeta-pbx-js" */)
}

